<!--
 * @Description: 绑定手机
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-17 20:43:14
 * @FilePath: /JinChanTuoKe-Front/src/components/binding/index.vue
-->
<template>
  <div class="bind-popup" @click="shows = false">
    <div class="bind" @click.stop>
      <div v-if="showQrCodeArea">
        <div class="bind-text">请扫描以下二维码绑定账号</div>
        <div class="bind-content">
          <img v-if="qrCodeValue" :src="qrCodeValue" alt="二维码" width="200" align="center ">
        </div>
      </div>
      <div v-if="showSmsCodeArea">
        <div class="bind-text">短信验证</div>
        <div class="bind-content">
          <div class="verification-block">
            <div class="input-row">
              <input
                type="text"
                v-model="code"
                placeholder="请输入验证码"
                class="code-input"
                :disabled="!canInput"
              />
              <span v-if="!canSend" class="countdown">{{ countdown }}</span>
            </div>
            <div class="spacing"></div>
            <div class="button-row">
              <button
                class="send-button"
                :disabled="!canSend"
                @click="sendCode"
              >
                发送验证码
              </button>
              <button
                class="verify-button"
                :disabled="!canVerify"
                @click="verifyCode"
              >
                验证
              </button>
            </div>
            <div class="button-row">
              <span v-if="!showMsg" class="countdown">{{ msg }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showPasswordArea">
        <div class="bind-text">密码验证</div>
        <div class="bind-content">
          <div class="verification-block">
            <div class="input-row">
              <input
                type="text"
                v-model="pwd"
                placeholder="请输入密码"
                class="code-input"
              />
            </div>
            <div class="spacing"></div>
            <div class="button-row">
              <button
                class="verify-button"
                @click="verifyPwd"
              >
                确定
              </button>
            </div>
          </div>
        </div>
      </div>      
      <div v-if="showAccountArea">
        <div class="bind-text">抖音号信息</div>
        <div class="bind-content">
          <div class="verification-block">
            <div class="input-row">
              <input
                type="text"
                v-model="showName"
                placeholder="抖音展示名称"
                class="code-input"
              />
            </div>
            <div class="spacing"></div>
            <div class="input-row">
              <input
                type="text"
                v-model="douyinId"
                placeholder="抖音号"
                class="code-input"
              />
            </div>
            <div class="spacing"></div>
            <div>
              <div class="radio-group">
                <label class="radio-label" v-for="(accountType, index) in accountTypes" :key="index">
                  <input type="radio" :value="accountType.value" v-model="selectedOption">
                  {{ accountType.label }}
                </label>
              </div>
            </div>
            <div class="spacing"></div>
            <div class="button-row">
              <button
                class="send-button"
                @click="showQrcode"
              >
                确认
              </button>
            </div>
            <div class="button-row">
              <span v-if="!showError" class="countdown">{{ errorMsg }}</span>
            </div>            
          </div>
        </div>
      </div>

      <div class="bind-close" @click="shows = false">
        <img src="../../assets/icon/close.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../utils/request";
import { MessageBox } from 'element-ui'; // 引入 Element UI 的 MessageBox 组件

const URL = {
  tiktokAuthorizationUrl: "api/customer/userAccount/tiktokAuthorizationUrl",
  checkQrCodeStatusUrl: "api/customer/userAccount/checkDouYinQrCodeStatus",
  sendCodeUrl: "api/customer/userAccount/sendDouYinSmsCode",
  verifyCodeUrl: "api/customer/userAccount/verifyDouYinSmsCode",
  verifyPwdUrl: "api/customer/userAccount/verifyDouYinPwd",
};
export default {
  data() {
    return {
      showAccountArea: true, // 初始状态为展示
      showQrCodeArea: false, // 初始状态为隐藏
      showSmsCodeArea: false, // 初始状态为隐藏
      showPasswordArea: false,
      showError: false,
      errorMsg: '',
      showMsg: false,
      msg: '',
      qrCodeValue : '',
      token : '',
      ttwId : '',
      code: '',
      verifyTicket: '',
      canSend: true, // 是否可发送验证码
      canInput: true, // 是否可输入验证码
      canVerify: false, // 是否可验证
      countdown: 60, // 倒计时
      timer: null, // 倒计时计时器
      showName: '',
      douyinId: '',
      pwd: '',
      accountTypes: [
        { value: 'Normal', label: '普通账号' },
        { value: 'Org', label: '机构号' }
      ],
      selectedOption: 'Normal' // 默认选中普通账号
    };
  },
  props: {
    accountId: Number, // 接收 accountId 属性
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  mounted() {
    if(this.$props.accountId!=null){
      this.showQrCodeArea = true;
      this.showAccountArea = false;
      this.fetchImageData();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval); // 组件销毁前清除定时器
    clearInterval(this.timer);
  },
  
  methods: {
    showQrcode() {
      if(this.showName.trim() == ''){
        this.showMsg = true;
        this.errorMsg = '请输入抖音展示名称';
        return;
      }

      if(this.douyinId.trim() == ''){
        this.showMsg = true;
        this.errorMsg = '请输入抖音号';
        return;
      }
        
      this.showMsg = false;
      this.errorMsg = '';
      this.showAccountArea = false;
      this.showQrCodeArea = true;
      this.fetchImageData();
    },

    async fetchImageData() {
    try {
        const res = await HTTP({
          url: URL.tiktokAuthorizationUrl,
          method: "get",
        });
        this.qrCodeValue = `data:image/png;base64,${res.qrCode}`;
        this.token = res.dytoken;
        this.ttwId = res.ttwId;
        this.interval = setInterval(() => {
          this.fetchApi();
        }, 5000); // 每 5 秒执行一次
      } catch (error) {
        console.error('Error fetching base64 URL:', error);
      }
  },
  async fetchApi() {
    const res = await HTTP({
          url: URL.checkQrCodeStatusUrl+'?dytoken='+this.token+'&ttwId='+this.ttwId+'&accountId='+this.accountId,
          method: "get",
        });
    if(res.error_code == 2046){
      const hasPwdVerify = res.verify_ways.some(item => item.verify_way === "pwd_verify");
      if(!hasPwdVerify){
        this.showQrCodeArea = false;
        this.showSmsCodeArea = true;
        clearInterval(this.interval)
        this.verifyTicket = res.verify_ticket;
      }else{
        this.showQrCodeArea = false;
        this.showPasswordArea = true;
        clearInterval(this.interval)
        this.verifyTicket = res.verify_ticket;      
      }
    }
    },
  async sendCode() {
      const res = await HTTP({
          url: URL.sendCodeUrl,
          method: "post",
          data: {
            'ttwId': this.ttwId,
            'verifyTicket': this.verifyTicket 
          }
      });
      if(res.status_code != 200){
          this.showMsg = true;
          this.msg = '发送验证码失败，请稍后重试';
      }
      // 发送验证码请求
      // ... 
      this.canSend = false;
      this.canVerify = true;
      this.canInput = true;
      this.startCountdown();
    },
  startCountdown() {
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.stopCountdown();
          this.canSend = true;
          this.canInput = true;
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.countdown = 60;
    },
    async verifyCode() {
      if(this.code.trim() == ''){
        this.showMsg = true;
        this.msg = '请输入验证码';
        return;
      }else{
        this.showMsg = false;
        this.msg = '';
      }
      const res = await HTTP({
          url: URL.verifyCodeUrl,
          method: "post",
          data: {
            'code': this.code,
            'ttwId': this.ttwId,
            'verifyTicket': this.verifyTicket,
            'token': this.token,
            'showName': this.showName,
            'douyinId': this.douyinId,
            'accountId': this.$props.accountId,
            'accountType': this.selectedOption,
          }
      });
      if(res.status_code != 200){
          this.showMsg = true;
          this.msg = '验证码校验失败，请重新输入';
      }else{
        this.shows = false;
        MessageBox.alert('抖音账号绑定成功', '提示信息', {
          confirmButtonText: '确定',
          type: 'success', // 可选：'success', 'info', 'warning', 'error'
        });  
      }
    },
    async verifyPwd() {
      if(this.pwd.trim() == ''){
        return;
      }
      const res = await HTTP({
          url: URL.verifyPwdUrl,
          method: "post",
          data: {
            'pwd': this.pwd,
            'ttwId': this.ttwId,
            'verifyTicket': this.verifyTicket,
            'token': this.token,
            'showName': this.showName,
            'douyinId': this.douyinId,
            'accountId': this.$props.accountId,
            'accountType': this.selectedOption,
          }
      });
      if(res.status_code != 200){
          this.showMsg = true;
          this.msg = '验证码校验失败，请重新输入';
      }else{
        this.shows = false;
        MessageBox.alert('抖音账号绑定成功', '提示信息', {
          confirmButtonText: '确定',
          type: 'success', // 可选：'success', 'info', 'warning', 'error'
        });  
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.verification-block {
  margin-top: 20px;
}

.input-row,
.button-row {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.code-input {
  width: 200px;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-button,
.verify-button {
  padding: 8px 16px;
  border: 1px solid #4586f0;
  border-radius: 4px;
  background-color: #f6f9ff;
  color: #4586f0;
  cursor: pointer;
  margin-right: 10px;
}

.send-button:disabled,
.verify-button:disabled {
  opacity: 0.5;
  cursor: default;
}

.countdown {
  margin-right: 10px;
  font-size: 16px;
  color: red;
}

.spacing {
  height: 20px; /* 设置间距高度 */
}

.radio-group {
  display: flex;
  /* 横向布局 */
}

.radio-label {
  margin-right: 10px; /* 添加选项之间的间距 */
  font-size: 14px; /* 设置字体大小为 14px */
}

.bind-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .bind {
    width: 400px;
    height: 400px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 20px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-tag {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
      font-weight: normal;
      color: #3b3b3b;
      line-height: 24px;
      padding-left: 27px;
      margin-top: 27px;
      span {
        color: #4e6bef;
        font-weight: bold;
      }
    }
    &-content {
      width: 400px;
      height: 300px;
      border: 1px solid #d5d7e0;
      display: flex;
      align-items: center;
      justify-content: center;
      // overflow-y: auto;
      // .content-item {
      //   width: 100%;
      // }
    }

    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
